.header {
  height: 10vh;
  width: 100%;
  padding: 25px 10vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  position: fixed;
  z-index: 9;
  transition: background-color 0.7s ease;
}

.nav {
  display: flex;

  & > * {
    padding-left: 50px;
  }
}

.scrolled {
  background-color: rgb(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 5);
}
.right-content {
  img {
    background-color: yellow;
  }
}

@media (max-width: 1200px) {
  .logo {
    width: 100px;
    height: 50px;
  }
  .header {
    padding: 10px 20px;
  }
  .scrolled {
    height: 7.5vh;
  }
}
