.header {
  z-index: 9;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
  padding: 25px 10vw;
  transition: background-color .7s;
  display: flex;
  position: fixed;
  top: 0;
}

.nav {
  display: flex;
}

.nav > * {
  padding-left: 50px;
}

.scrolled {
  backdrop-filter: blur(20px);
  background-color: #0009;
  box-shadow: 0 0 10px #000;
}

.right-content img {
  background-color: #ff0;
}

@media (width <= 1200px) {
  .logo {
    width: 100px;
    height: 50px;
  }

  .header {
    padding: 10px 20px;
  }

  .scrolled {
    height: 7.5vh;
  }
}
/*# sourceMappingURL=index.86358148.css.map */
